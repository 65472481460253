var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"centered":"","color":_vm.accentColor},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Crear")]),_c('v-tab',[_vm._v("Duplicar")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createHook","constants":{ environmentId: _vm.environmentId },"submitButtonText":"Crear","submitButtonIcon":"add","submit-button-color":_vm.accentColor,"environment-variables":{ environmentId: _vm.environmentId },"schema":{
            environmentId: {
              __graphQLType: 'ID'
            },
            name: {
              label: 'Nombre para identificar el hook',
              type: 'string',
              description: 'Debe ser único y reconocible.',
              fieldOptions: {
                color: _vm.accentColor
              },
              newDesign: true
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v("Se duplicará:")]),_c('div',{staticClass:"caption"},[_vm._v("- El hook seleccionado y todas sus opciones")]),_c('div',{staticClass:"overline"},[_vm._v("NO se duplicará:")]),_c('div',{staticClass:"caption"},[_vm._v("- ID o nombre del hook")]),_c('div',{staticClass:"caption"},[_vm._v(" - Cualquier componente (colecciones, filtros, etc) al que este hook haga referencia. ")]),_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateHook","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":"Duplicar","submitButtonIcon":"content_copy","submit-button-color":_vm.accentColor,"schema":{
            hookId: {
              __graphQLType: 'ID',
              label: 'Hook a copiar',
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'hooks'
              },
              newDesign: true
            },
            newName: {
              label: 'Nombre para el nuevo hook',
              type: 'string',
              description: 'Debe ser único y reconocible.',
              fieldOptions: {
                color: _vm.accentColor
              },
              newDesign: true,
              isDark: this.isDark
            }
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }